import { LitElement, html, nothing } from "lit";
import { App } from "@nxcode-npm/navjs";
export class ErrorPage extends LitElement {
    constructor() {
        super();
        this.text = App.app.router.pages['error'].params['text'];
    }
    createRenderRoot() {
        return this;
    }
    render() {
        return html `<div class="p-3">Siamo spiacenti, si &ègrave un errore di sistema. Si prega di controllare
         la connessione ad Internet e riprovare.</div>
         ${this.text && this.text != "" ?
            html `<div class="p-3">${this.text}</div>` : nothing}
         <button class="btn btn-primary mb-3">Ricarica Map2</button>`;
    }
}
