import { LitElement, html } from "lit";
import { Nav } from "@nxcode-npm/navjs";
import flatpickr from "flatpickr";
import { App } from "@nxcode-npm/navjs";
import { addDoc, collection } from "firebase/firestore";
import { currentDateTime } from "../../../../../wc/wc/dev/src/lib/util";
var autocomplete;
var autocompleteAddress;
export class PinMapPage extends LitElement {
    constructor() {
        super();
        this.placeChanged = () => {
            var _a, _b;
            this.placeResult = autocomplete.getPlace();
            this.address = this.placeResult.formatted_address;
            this.lat = (_a = this.placeResult.geometry.location) === null || _a === void 0 ? void 0 : _a.lat();
            this.lng = (_b = this.placeResult.geometry.location) === null || _b === void 0 ? void 0 : _b.lng();
            document.getElementById("google-map-autocomplete-address").value = this.address;
            //console.debug("place", this.placeResult)
            //console.debug("address", this.address)
        };
        this.addressChanged = () => {
            var _a, _b;
            this.addressResult = autocompleteAddress.getPlace();
            this.address = this.addressResult.formatted_address;
            this.lat = (_a = this.addressResult.geometry.location) === null || _a === void 0 ? void 0 : _a.lat();
            this.lng = (_b = this.addressResult.geometry.location) === null || _b === void 0 ? void 0 : _b.lng();
            //console.log("addressChanged", this.addressResult)
        };
        this.newOpening = false;
        this.lat = 0.000;
        this.lng = 0.000;
        this.category = "";
        this.contentType = "";
        this.eventTypes = [];
        this.msg = "";
        this.startDate = "";
        this.endDate = "";
        this.db = App.app.state["db"];
        //console.log("db", this.db)
    }
    createRenderRoot() {
        return this;
    }
    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.initAutocomplete();
        //flatpickr
        const startDatePickr = flatpickr("#startDate", {});
        const endDatePickr = flatpickr("#endDate", {});
    }
    render() {
        return html `<style>
            label {
                color:white !important;
                margin-bottom:4px !important;
            }
        </style><header>
        <nav class="navbar navbar-light bg-light">
  <div class="container-fluid">
        <span class="text-white" @click="${() => Nav({ name: 'index', params: {} })}"><i class="bi bi-arrow-left"></i> Ritorna alla mappa</span>
        <span  class="navbar-brand text-white">Map2</span>
  </div>
  </nav>
        </header>
        <main class="p-2">
            <div class="mb-3">
                <label for="google-map-autocomplete">Cerca nome attivit&agrave; o organizzatore su Google Map</label>
                <input type="text" class="form-control" id="google-map-autocomplete" />
            </div>
            <div class="mb-3">
                <label for="google-map-autocomplete-address">Scrivi l' indirizzo o il luogo da inserire sulla mappa (se diverso da quello trovato in base al nome)</label>
                <input type="text" class="form-control" id="google-map-autocomplete-address" />
            </div>
            <!--<div class="mb-3">
                <label for="category">Categoria</label>
                <select class="form-select mb-3" id="category" @change="${(e) => { this.category = e.target.options[e.target.selectedIndex].value; }}">
                <option selected value="">Seleziona la categoria</option>
                <option value="1">Arredamento</option>
                <option value="2">Elettronica</option>
                <option value="3">...</option>
            </select>
            </div>-->
            <div class="mb-3">
                <label>Vuoi segnalare una nuova apertura ?</label>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="newOpening" value="" id="newOpeningYes" @change="${(e) => { e.target.checked ? this.newOpening = true : this.newOpening = false; }}">
                    <label class="form-check-label" for="newOpeningYes">Si</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="newOpening" value="" id="newOpeningNo" checked>
                    <label class="form-check-label" for="newOpeningNo">No</label>
                </div>
            </div>
            <div class="mb-3">
                <label>Tipo di annuncio</label>
                <select class="form-select" id="content" @change="${(e) => this.contentChanged(e)}">
                    <option selected>Seleziona</option>
                    <option value="shop">Sconti, offerte e promozioni</option>
                    <option value="event">Eventi</option>
                    <option value="job">Lavoro</option>
                </select>
            </div>
            <div class="d-none mb-3" id="eventTypes">
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="music" id="checkMusic">
                    <label class="form-check-label" for="flexCheckDefault">Musica</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="party" id="checkParty">
                    <label class="form-check-label" for="flexCheckDefault">Feste,sagre</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="cinema" id="checkCinema">
                    <label class="form-check-label" for="flexCheckDefault">Cinema</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="culture" id="checkCulture">
                    <label class="form-check-label" for="flexCheckDefault">Teatro e cultura</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="family" id="checkFamily">
                    <label class="form-check-label" for="flexCheckDefault">Famiglie, bambini</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="play" id="checkPlay">
                    <label class="form-check-label" for="flexCheckDefault">Giochi e divertimento</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="market" id="checkMarket">
                    <label class="form-check-label" for="flexCheckDefault">Mercati</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="newOpening" id="checkNewOpening">
                    <label class="form-check-label" for="flexCheckDefault">Nuove aperture</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="motori" id="checkMotor">
                    <label class="form-check-label" for="flexCheckDefault">Motori</label>
                </div>
                <div class="form-check me-2">
                    <input class="form-check-input" type="checkbox" value="sport" id="checkSport">
                    <label class="form-check-label" for="flexCheckDefault">Sport</label>
                </div>
            </div>
            <div class="row d-none" id="period">  
                <div class="col-12 col-md-6 mb-3">
                    <label for="from">Data inizio</label>
                    <input type="text" class="form-control" id="startDate" @change="${(e) => { this.startDate = e.target.value; }}" />
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label for="from">Data fine</label>
                    <input type="text" class="form-control" id="endDate" @change="${(e) => { this.endDate = e.target.value; }}" />
                </div>
            </div> 
            <div class="mb-3">
                <label for="msg">Testo annuncio</label>
                <textarea id="msg" name="msg" class="form-control" @change="${(e) => { this.msg = e.target.value; }}"></textarea>
            </div>
            <div>&nbsp;</div>
            <div class="mb-3 d-grid gap-2">
                <button type="button" class="btn btn-primary btn-lg" @click="${() => this.send()}"><i class="bi bi-geo-fill"></i> Aggiungi alla mappa</button>
            </div>     
        <main>`;
    }
    contentChanged(e) {
        this.contentType = e.target.options[e.target.selectedIndex].value;
        const period = document.getElementById("period");
        this.contentType = e.target.options[e.target.selectedIndex].value;
        const contentTypesContainer = this.renderRoot.querySelector("#eventTypes");
        //const contentTypes = contentTypesContainer.querySelectorAll("input")
        if (this.contentType == "event") {
            contentTypesContainer.classList.remove("d-none");
            contentTypesContainer.classList.add("d-flex");
            contentTypesContainer.classList.add("flex-wrap");
        }
        else {
            contentTypesContainer.classList.remove("d-flex");
            contentTypesContainer.classList.remove("flex-wrap");
            contentTypesContainer.classList.add("d-none");
        }
        if (this.contentType != "job") {
            period.classList.remove("d-none");
        }
        else {
            period.classList.add("d-none");
        }
    }
    send() {
        console.log("place", this.placeResult);
        /*console.log("address", this.address)
        console.log("newOpening", this.newOpening)
        console.log("contentType", this.contentType)
        console.log("startDate", this.startDate)
        console.log("endDate", this.endDate)*/
        if (this.checkForm()) {
            const data = {
                date: currentDateTime(),
                placeName: this.placeResult.name,
                placeId: this.placeResult.place_id,
                address: this.address,
                lat: this.lat,
                lng: this.lng,
                //category: this.category,
                newOpening: this.newOpening,
                contentType: this.contentType,
                eventTypes: this.eventTypes,
                msg: this.msg,
                startDate: this.startDate,
                endDate: this.endDate
            };
            console.log(data, data);
            // Add a new document with a generated id.
            addDoc(collection(this.db, "markers"), data).then(docRef => {
                console.log("Document written with ID: ", docRef.id);
            }).catch(err => console.error(err));
        }
        else {
            alert("Completare il form!");
        }
    }
    checkForm() {
        let valid = true;
        const place = document.getElementById("google-map-autocomplete").value;
        const address = document.getElementById("google-map-autocomplete-address").value;
        if (!place || place.trim() == "")
            valid = false;
        if (!address || address.trim() == "")
            valid = false;
        //if(!this.category || this.category == "") valid = false; 
        if (!this.contentType || this.contentType == "")
            valid = false;
        if (!this.msg || this.msg.trim() == "")
            valid = false;
        if (this.contentType != "job") {
            if (!this.startDate || this.startDate == "")
                valid = false;
            if (!this.endDate || this.endDate == "")
                valid = false;
        }
        if (this.contentType == "event") {
            const contentTypes = this.renderRoot.querySelector("#eventTypes").querySelectorAll("input");
            for (let t of contentTypes) {
                //console.log(t.checked, t.value)
                if (t.checked) {
                    this.eventTypes.push(t.value);
                }
            }
            if (this.eventTypes.length == 0) {
                valid = false;
            }
        }
        return valid;
    }
    initAutocomplete() {
        const autocompleteInput = this.renderRoot.querySelector("#google-map-autocomplete");
        const autocompleteInputAddress = this.renderRoot.querySelector("#google-map-autocomplete-address");
        // Create the autocomplete object, restricting the search predictions to
        // addresses in the US and Canada.
        autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
            componentRestrictions: { country: ["it"] },
            fields: ["formatted_address", "name", "geometry", "place_id"]
            //types: ["address"],
            //types: ["administrative_area_level_3","administrative_area_level_2","administrative_area_level_1","postal_code", "country"],
            //types:this.data.types,
        });
        autocompleteInput.focus();
        autocomplete.addListener("place_changed", this.placeChanged);
        autocompleteAddress = new google.maps.places.Autocomplete(autocompleteInputAddress, {
            componentRestrictions: { country: ["it"] },
            fields: ["formatted_address", "geometry"]
            //types: ["address"],
            //types: ["administrative_area_level_3","administrative_area_level_2","administrative_area_level_1","postal_code", "country"],
            //types:this.data.types,
        });
        autocompleteAddress.addListener("place_changed", this.addressChanged);
    }
}
