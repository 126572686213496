// STYLES
import { appRouter } from "./src/appRouter"
import "./src/styles/styles.scss"

// PAGES
import { IndexPage } from "./src/pages/index/index-page"
import { PinMapPage } from "./src/pages/pin-map/pin-map.page"
import { ErrorPage } from "./src/pages/error/error.page"

customElements.define("index-page", IndexPage)
customElements.define("pin-map-page", PinMapPage)
customElements.define("error-page", ErrorPage)

import { createApp } from "@nxcode-npm/navjs"

createApp({name:'test-app', router: appRouter, state: {}}, {name:'index', params: {}})