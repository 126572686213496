export const appRouter = {
    pages: {
        'index': {
            el: 'index-page',
            params: {},
            state: {}
        },
        'pin-map': {
            el: 'pin-map-page',
            params: {},
            state: {}
        },
        'error': {
            el: 'error-page',
            params: {},
            state: {}
        }
    }
};
