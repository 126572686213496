var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LitElement, html } from "lit";
//import { Loader } from "@googlemaps/js-api-loader"
import { App, Nav } from "@nxcode-npm/navjs";
import { initializeApp } from "firebase/app";
import { getFirestore, query, getDocs, collection } from "firebase/firestore";
import { appConfig } from "../../config";
import { Modal } from "bootstrap";
const markerStyles = {
    scale: 1.2,
    styles: {
        job: {
            background: 'blue',
            borderColor: 'blue',
            glyph: ' fa fa-pizza-slice ',
            glyphColor: 'white'
        },
        event: {
            background: 'red',
            borderColor: 'red',
            glyph: ' fa fa-pizza-slice ',
            glyphColor: 'white'
        },
        shop: {
            background: 'green',
            borderColor: 'green',
            glyph: ' fa fa-pizza-slice ',
            glyphColor: 'white'
        },
        newOpening: {
            background: 'yellow',
            borderColor: 'yellow',
            glyph: ' fa fa-pizza-slice ',
            glyphColor: 'white'
        }
    }
};
export class IndexPage extends LitElement {
    constructor() {
        super();
        this.markers = [];
    }
    createRenderRoot() {
        return this;
    }
    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        const bsModal = new Modal(document.querySelector(".modal"));
        bsModal.show();
        const loader = null;
        try {
            // load maps library
            this.loadMap();
            // init db
            this.initDb();
            // get markers
            this.getMarkers().then(() => {
                this.loadMarkers();
            });
        }
        catch (err) {
            console.error(err);
            //Nav({name:'error',params:{text:""}})
        }
    }
    initDb() {
        // Initialize Firebase
        const app = initializeApp(appConfig.firebaseConfig);
        // Initialize Cloud Firestore and get a reference to the service
        this.db = getFirestore(app);
        //console.debug('firestore', db)
        App.app.state["db"] = this.db;
    }
    getMarkers() {
        return __awaiter(this, void 0, void 0, function* () {
            const collRef = collection(this.db, "markers");
            const q = query(collRef);
            const querySnapshot = yield getDocs(q);
            querySnapshot.forEach((doc) => {
                var _a;
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                (_a = this.markers) === null || _a === void 0 ? void 0 : _a.push({ id: doc.id, data: doc.data() });
            });
        });
    }
    loadMarkers() {
        return __awaiter(this, void 0, void 0, function* () {
            const { AdvancedMarkerElement } = yield google.maps.importLibrary("marker");
            //TEST
            //const icon = document.createElement('div');
            //icon.innerHTML = '<i class="fa fa-pizza-slice fa-lg"></i>';
            // Adjust the scale.
            /*const pinScaled = new google.maps.marker.PinElement({
                scale: 1.5,
                background:'#000000',
                borderColor:'red',
                glyph:icon,
                glyphColor:'green'
            });*/
            let pinScaled;
            for (let marker of this.markers) {
                console.log('marker', marker);
                const markerCSS = this.getMarkerStyles(marker);
                // create icon element
                const icon = document.createElement('div');
                icon.innerHTML = `<i class="${markerCSS["glyph"]} fa-lg"></i>`;
                // create the PinElement
                pinScaled = new google.maps.marker.PinElement({
                    scale: markerStyles.scale,
                    background: markerCSS["background"],
                    borderColor: markerCSS["borderColor"],
                    glyph: icon,
                    glyphColor: markerCSS["glyphColor"]
                });
                new AdvancedMarkerElement({
                    map: this.map,
                    position: { lat: marker.data.lat, lng: marker.data.lng },
                    title: marker.data.placeName,
                    content: pinScaled.element
                });
            }
        });
    }
    getMarkerStyles(marker) {
        if (marker.data.newOpening) {
            return markerStyles.styles.newOpening;
        }
        else {
            const contentType = marker.data["contentType"];
            //const eventType = marker.data["eventType"]
            switch (contentType) {
                case "job":
                    return markerStyles.styles.job;
                    break;
                case "event":
                    return markerStyles.styles.event;
                    break;
                case "shop":
                    return markerStyles.styles.shop;
                    break;
                default:
                    console.error(`no marker style found for ${contentType}`);
                    return { background: "", borderColor: "", glyphColor: "" };
            }
        }
    }
    loadMap() {
        return __awaiter(this, void 0, void 0, function* () {
            // Request needed libraries.
            const { Map } = yield google.maps.importLibrary("maps");
            //const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
            //const { Map } = await loader.importLibrary("maps")
            //const { AdvancedMarkerElement } = await loader.importLibrary("marker")
            // The location of Brescia
            const position = { lat: 45.543, lng: 10.141 };
            let mapOptions = { center: position, zoom: 8, mapId: 'DEMO_MAP_ID', mapTypeControl: false };
            this.map = new Map(document.getElementById("map"), mapOptions);
        });
    }
    render() {
        return html `<header>
        <nav class="navbar">
  <div class="container-fluid">
  <span class="navbar-brand text-white">
  <i class="bi bi-balloon"></i><span class="calistoga-regular">${appConfig.appName}</span></span>
        <div class="fs-4" style="color:var(--color-secondary)">
            <i class="bi bi-geo-fill me-2"  @click="${() => Nav({ name: 'pin-map', params: {} })}"></i>
            <!--<i class="bi bi-search me-2"></i>-->
        </div>
        
  </div>
  </nav>
        </header>
        <main><div id='map'></div></main>
        <footer class="text-center mb-3">
         <div class="mb-3">
           map2.web.app<br/>Sconti e offerte speciali, novità e promozioni, eventi, lavoro
         </div>
         <!--<div class="mb-3">
            <button type="button" class="btn btn-primary" @click="${() => Nav({ name: 'pin-map', params: {} })}">
                <i class="bi bi-geo-fill"></i> <span class="calistoga-regular">Aggiungi un marker</span></button>
                 <button type="button" class="btn btn-white" @click="${() => this.hideModal('modalStart')}">
            <i class="bi bi-crosshair2"></i> <span class="calistoga-regular">Inizia a navigare</span></button>
         </div>-->      
        </footer>
        <!-- MODAL -->
        <div class="modal fade" id="modalStart" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="fs-4 text-white">
            <i class="bi bi-balloon"></i><span class="calistoga-regular">${appConfig.appName}</span>
        </div>
      </div>
      <div class="modal-body">
        <p class="text-center fs-4">Scopri cosa succede su Google Map</p>
        <div class="text-center fs-6">
            <div class="pb-2">Sconti e offerte speciali</div>
            <div class="pb-2">Nuove aperture e novit&agrave;</div>
            <div class="pb-2">Eventi</div>
            <div class="pb-2">Lavoro in locali, ristoranti, negozi e Hotel</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" @click="${() => this.navToPin()}">
            <i class="bi bi-geo-fill"></i> <span class="calistoga-regular">Aggiungi un marker</span></button>
        <button type="button" class="btn btn-secondary btn-sm" @click="${() => this.hideModal('modalStart')}">
            <i class="bi bi-crosshair2"></i> <span class="calistoga-regular">Inizia a navigare</span></button>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL -->`;
    }
    navToPin() {
        this.hideModal('modalStart');
        Nav({ name: 'pin-map', params: {} });
    }
    hideModal(id) {
        Modal.getInstance(`#${id}`).hide();
    }
}
