export const appConfig = {
    appName: "Mapspot",
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    /*firebaseConfig: {
      apiKey: "AIzaSyBg3tW9VhM4ASbeFq1EgNMmePly0KLEdLo",
      authDomain: "nxcode-cloud.firebaseapp.com",
      projectId: "nxcode-cloud",
      storageBucket: "nxcode-cloud.appspot.com",
      messagingSenderId: "156693782947",
      appId: "1:156693782947:web:7cc8f98bce673504b1fa16",
      measurementId: "G-L52N9Z1HTZ"
  },*/
    firebaseConfig: {
        apiKey: "AIzaSyBg3tW9VhM4ASbeFq1EgNMmePly0KLEdLo",
        authDomain: "nxcode-cloud.firebaseapp.com",
        projectId: "nxcode-cloud",
        storageBucket: "nxcode-cloud.appspot.com",
        messagingSenderId: "156693782947",
        appId: "1:156693782947:web:4afbe7b20f04ed8cb1fa16",
        measurementId: "G-1X27DY3PH1"
    }
};
